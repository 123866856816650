import httpClient  from '.';
const API_CONTROLLER = 'report-registration/';

export default {
    getReportSummary(search) {
        let url = API_CONTROLLER + 'get-summary';
        return httpClient.get(url, {
            params: {
                pageIndex: search.pageIndex,
                pageSize: search.pageSize,
                fromDate: search.fromDate,
                toDate: search.toDate,
                vehicleId: search.vehicleId
            }
        });
    },

    getReportVehicle(search) {
        let url = API_CONTROLLER + 'get-vehicle';
        return httpClient.get(url, {
            params: {
                licensePlate: search.licensePlate
            }
        });
    },
}